export default function useNoteConverter() {

  const convertSharedImages = (content: string, sid: string, token: string) => {
    if (!content) {
      return content;
    }
    return content.replaceAll(
      '(/api/notes/image?path=',
      `(/api/sharedNotes/image?sid=${sid}&token=${token}&path=`
    );
  }

  return {
    convertSharedImages
  }
}
